import { writable } from "svelte/store"
import { location } from "svelte-spa-router"
import { valuta_per_dominio } from "@/portale/sorgenti/valuta_per_dominio"

// Dominio.
const indirizzo_dominio = function() {
  return window.location.pathname.split("/")[1]
}
// Valuta per dominio.
const imposta_valuta = function() {
  switch (indirizzo_dominio()) {
    case "ticino":
      return "CHF"
  
    default:
      return "&euro;"
  }
}
const dominio = writable(null)

dominio.su_cambio = dominio.subscribe
location.subscribe(rotta => {
  dominio.update(indirizzo_dominio)
  valuta_per_dominio.update(imposta_valuta)
})

// Indirizzi.
const esempio_trentino = [
  "http://account.hbenchmark.loc:5000/trentino#/registrazione?",
  "sid=1083&did=&gid=1385&nomeStruttura=LUNA+WELLNESS+HOTEL&",
  "indirizzoStruttura=VIA+DEI+BRENZI%2C+32&citt%C3%A0Struttura=DIMARO&",
  "provinciaStruttura=TN&capStruttura=38025&telefonoStruttura=%2B39%280463%29986305&",
  "nome=Gianni&cognome=Tonato&ref_id=59fc33c4bd37c6a3ad194da8" ].join("")

export { 
  dominio,
  esempio_trentino }
