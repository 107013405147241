/*
  -- STRINGHE --
  Varie funzioni di grande utilità che estendono il prototipo
  della classe String.
*/


String.prototype.starts_with = String.prototype.startsWith
String.prototype.include = String.prototype.includes

// In maiuscolo.
Object.defineProperty(String.prototype, "upcase", {
  get: function() {
    return this.toUpperCase()
  },
  enumerable: false
})

// Prima lettera maiuscola.
Object.defineProperty(String.prototype, "capitalize", {
  get: function() {
    return this[0].toUpperCase() + this.slice(1)
  },
  enumerable: false
})

// In minuscolo.
Object.defineProperty(String.prototype, "downcase", {
  get: function() {
    return this.toLowerCase()
  },
  enumerable: false
})

// Converte in data.
Object.defineProperty(String.prototype, "to_date", {
  get: function() {
    return Date.convert(this)
  },
  enumerable: false
})
