const nazionalità = {
   "IT":              { it: "Italia",                                               en: "Italy",                                                            de: "Italien" },
   "CH":              { it: "Svizzera",                                             en: "Switzerland",                                                      de: "Schweiz" },
   "RU":              { it: "Russia",                                               en: "Russian Federation",                                               de: "Russische Föderation" },
   "CN":              { it: "Cina",                                                 en: "China",                                                            de: "China" },
   "US":              { it: "Stati Uniti",                                          en: "United States",                                                    de: "Vereinigte Staaten" },
   "CA":              { it: "Canada",                                               en: "Canada",                                                           de: "Kanada" },
   "GB":              { it: "Regno Unito",                                          en: "United Kingdom",                                                   de: "Vereinigtes Königreich" },
   "ES":              { it: "Spagna",                                               en: "Spain",                                                            de: "Spanien" },
   "DE":              { it: "Germania",                                             en: "Germany",                                                          de: "Deutschland" },
   "FR":              { it: "Francia",                                              en: "France",                                                           de: "Frankreich" },
   "NL":              { it: "Paesi Bassi",                                          en: "Netherlands",                                                      de: "Niederlande" },
   "BE":              { it: "Belgio",                                               en: "Belgium",                                                          de: "Belgien" },
   "SE":              { it: "Svezia",                                               en: "Sweden",                                                           de: "Schweden" },
   "NO":              { it: "Norvegia",                                             en: "Norway",                                                           de: "Norwegen" },
   "FI":              { it: "Finlandia",                                            en: "Finland",                                                          de: "Finnland" },
   "DK":              { it: "Danimarca",                                            en: "Denmark",                                                          de: "Dänemark" },
   "AE":              { it: "Emirati Arabi Uniti",                                  en: "United Arab Emirates",                                             de: "Vereinigte Arabische Emirate" },
   "JP":              { it: "Giappone",                                             en: "Japan",                                                            de: "Japan" },
   "AU":              { it: "Australia",                                            en: "Australia",                                                        de: "Australien" },
   "IN":              { it: "India",                                                en: "India",                                                            de: "Indien" },
   "ZA":              { it: "Sud Africa",                                           en: "South Africa",                                                     de: "Südafrika" },
   "LU":              { it: "Lussemburgo",                                          en: "Luxembourg",                                                       de: "Luxemburg" },
   "IE":              { it: "Irlanda",                                              en: "Ireland",                                                          de: "Irland" },
   "CZ":              { it: "Cechia",                                               en: "Czechia",                                                          de: "Tschechien" },
   "SK":              { it: "Slovacchia",                                           en: "Slovakia",                                                         de: "Slowakei" },
   "SI":              { it: "Slovenia",                                             en: "Slovenia",                                                         de: "Slowenien" },
   "HU":              { it: "Ungheria",                                             en: "Hungary",                                                          de: "Ungarn" },
   "RO":              { it: "Romania",                                              en: "Romania",                                                          de: "Rumänien" },
   "BG":              { it: "Bulgaria",                                             en: "Bulgaria",                                                         de: "Bulgarien" },
   "BY":              { it: "Bielorussia",                                          en: "Belarus",                                                          de: "Weißrussland" },
   "UA":              { it: "Ucraina",                                              en: "Ukraine",                                                          de: "Ukraine" },
   "AW":              { it: "Aruba",                                                en: "Aruba",                                                            de: "Aruba" },
   "AF":              { it: "Afghanistan",                                          en: "Afghanistan",                                                      de: "Afghanistan" },
   "AO":              { it: "Angola",                                               en: "Angola",                                                           de: "Angola" },
   "AI":              { it: "Anguilla",                                             en: "Anguilla",                                                         de: "Anguilla" },
   "AX":              { it: "Isole Åland",                                          en: "Åland Islands",                                                    de: "Åland-Inseln" },
   "AL":              { it: "Albania",                                              en: "Albania",                                                          de: "Albanien" },
   "AD":              { it: "Andorra",                                              en: "Andorra",                                                          de: "Andorra" },
   "AR":              { it: "Argentina",                                            en: "Argentina",                                                        de: "Argentinien" },
   "AM":              { it: "Armenia",                                              en: "Armenia",                                                          de: "Armenien" },
   "AS":              { it: "Samoa americane",                                      en: "American Samoa",                                                   de: "Amerikanisch-Samoa" },
   "AQ":              { it: "Antartide",                                            en: "Antarctica",                                                       de: "Antarktis" },
   "TF":              { it: "Territori meridionali francesi",                       en: "French Southern Territories",                                      de: "Französische Süd- und Antarktisgebiete" },
   "AG":              { it: "Antigua e Barbuda",                                    en: "Antigua and Barbuda",                                              de: "Antigua und Barbuda" },
   "AT":              { it: "Austria",                                              en: "Austria",                                                          de: "Österreich" },
   "AZ":              { it: "Azerbaigian",                                          en: "Azerbaijan",                                                       de: "Aserbaidschan" },
   "BI":              { it: "Burundi",                                              en: "Burundi",                                                          de: "Burundi" },
   "BJ":              { it: "Benin",                                                en: "Benin",                                                            de: "Benin" },
   "BQ":              { it: "Bonaire, Saint Eustatius e Saba",                      en: "Bonaire, Sint Eustatius and Saba",                                 de: "Bonaire, Sint Eustatius und Saba" },
   "BF":              { it: "Burkina-Faso",                                         en: "Burkina Faso",                                                     de: "Burkina Faso" },
   "BD":              { it: "Bangladesh",                                           en: "Bangladesh",                                                       de: "Bangladesch" },
   "BH":              { it: "Bahrein",                                              en: "Bahrain",                                                          de: "Bahrain" },
   "BS":              { it: "Bahamas",                                              en: "Bahamas",                                                          de: "Bahamas" },
   "BA":              { it: "Bosnia-Erzegovina",                                    en: "Bosnia and Herzegovina",                                           de: "Bosnien und Herzegowina" },
   "BL":              { it: "Saint-Barths",                                         en: "Saint Barthélemy",                                                 de: "Saint-Barthélemy" },
   "BZ":              { it: "Belize",                                               en: "Belize",                                                           de: "Belize" },
   "BM":              { it: "Bermuda",                                              en: "Bermuda",                                                          de: "Bermuda" },
   "BO":              { it: "Bolivia",                                              en: "Bolivia",                                                          de: "Bolivien" },
   "BR":              { it: "Brasile",                                              en: "Brazil",                                                           de: "Brasilien" },
   "BB":              { it: "Barbados",                                             en: "Barbados",                                                         de: "Barbados" },
   "BN":              { it: "Brunei",                                               en: "Brunei Darussalam",                                                de: "Brunei Darussalam" },
   "BT":              { it: "Bhutan",                                               en: "Bhutan",                                                           de: "Bhutan" },
   "BV":              { it: "Isola di Bouvet",                                      en: "Bouvet Island",                                                    de: "Bouvet-Insel" },
   "BW":              { it: "Botswana",                                             en: "Botswana",                                                         de: "Botsuana" },
   "CF":              { it: "Repubblica Centrafricana",                             en: "Central African Republic",                                         de: "Zentralafrikanische Republik" },
   "CC":              { it: "Isole Cocos (Keeling)",                                en: "Cocos (Keeling) Islands",                                          de: "Kokos-(Keeling-)Inseln" },
   "CL":              { it: "Cile",                                                 en: "Chile",                                                            de: "Chile" },
   "CI":              { it: "Costa d'Avorio",                                       en: "Côte d'Ivoire",                                                    de: "Côte d'Ivoire" },
   "CM":              { it: "Camerun",                                              en: "Cameroon",                                                         de: "Kamerun" },
   "CD":              { it: "Repubblica democratica del Congo",                     en: "Congo, The Democratic Republic of the",                            de: "Demokratische Republik Kongo" },
   "CG":              { it: "Congo",                                                en: "Congo",                                                            de: "Kongo" },
   "CK":              { it: "Isole Cook",                                           en: "Cook Islands",                                                     de: "Cookinseln" },
   "CO":              { it: "Colombia",                                             en: "Colombia",                                                         de: "Kolumbien" },
   "KM":              { it: "Comore",                                               en: "Comoros",                                                          de: "Komoren" },
   "CV":              { it: "Capo Verde",                                           en: "Cabo Verde",                                                       de: "Cabo Verde" },
   "CR":              { it: "Costa Rica",                                           en: "Costa Rica",                                                       de: "Costa Rica" },
   "CU":              { it: "Cuba",                                                 en: "Cuba",                                                             de: "Kuba" },
   "CW":              { it: "Curaçao",                                              en: "Curaçao",                                                          de: "Curaçao" },
   "CX":              { it: "Isola Christmas",                                      en: "Christmas Island",                                                 de: "Weihnachtsinseln" },
   "KY":              { it: "Isole Cayman",                                         en: "Cayman Islands",                                                   de: "Cayman-Inseln" },
   "CY":              { it: "Cipro",                                                en: "Cyprus",                                                           de: "Zypern" },
   "DJ":              { it: "Gibuti",                                               en: "Djibouti",                                                         de: "Dschibuti" },
   "DM":              { it: "Dominica",                                             en: "Dominica",                                                         de: "Dominica" },
   "DO":              { it: "Repubblica Dominicana",                                en: "Dominican Republic",                                               de: "Dominikanische Republik" },
   "DZ":              { it: "Algeria",                                              en: "Algeria",                                                          de: "Algerien" },
   "EC":              { it: "Ecuador",                                              en: "Ecuador",                                                          de: "Ecuador" },
   "EG":              { it: "Egitto",                                               en: "Egypt",                                                            de: "Ägypten" },
   "ER":              { it: "Eritrea",                                              en: "Eritrea",                                                          de: "Eritrea" },
   "EH":              { it: "Sahara occidentale",                                   en: "Western Sahara",                                                   de: "Westsahara" },
   "EE":              { it: "Estonia",                                              en: "Estonia",                                                          de: "Estland" },
   "ET":              { it: "Etiopia",                                              en: "Ethiopia",                                                         de: "Äthiopien" },
   "FJ":              { it: "Fiji",                                                 en: "Fiji",                                                             de: "Fidschi" },
   "FK":              { it: "Isole Falkland (Malvine)",                             en: "Falkland Islands (Malvinas)",                                      de: "Falklandinseln (Malwinen)" },
   "FO":              { it: "Isole Fær Øer",                                        en: "Faroe Islands",                                                    de: "Färöer-Inseln" },
   "FM":              { it: "Micronesia",                                           en: "Micronesia, Federated States of",                                  de: "Mikronesien, Föderierte Staaten von" },
   "GA":              { it: "Gabon",                                                en: "Gabon",                                                            de: "Gabun" },
   "GE":              { it: "Georgia",                                              en: "Georgia",                                                          de: "Georgien" },
   "GG":              { it: "Guernsey",                                             en: "Guernsey",                                                         de: "Guernsey" },
   "GH":              { it: "Ghana",                                                en: "Ghana",                                                            de: "Ghana" },
   "GI":              { it: "Gibilterra",                                           en: "Gibraltar",                                                        de: "Gibraltar" },
   "GN":              { it: "Guinea",                                               en: "Guinea",                                                           de: "Guinea" },
   "GP":              { it: "Guadalupa",                                            en: "Guadeloupe",                                                       de: "Guadeloupe" },
   "GM":              { it: "Gambia",                                               en: "Gambia",                                                           de: "Gambia" },
   "GW":              { it: "Guinea-Bissau",                                        en: "Guinea-Bissau",                                                    de: "Guinea-Bissau" },
   "GQ":              { it: "Guinea equatoriale",                                   en: "Equatorial Guinea",                                                de: "Äquatorialguinea" },
   "GR":              { it: "Grecia",                                               en: "Greece",                                                           de: "Griechenland" },
   "GD":              { it: "Grenada",                                              en: "Grenada",                                                          de: "Grenada" },
   "GL":              { it: "Groenlandia",                                          en: "Greenland",                                                        de: "Grönland" },
   "GT":              { it: "Guatemala",                                            en: "Guatemala",                                                        de: "Guatemala" },
   "GF":              { it: "Guyana francese",                                      en: "French Guiana",                                                    de: "Französisch-Guyana" },
   "GU":              { it: "Guam",                                                 en: "Guam",                                                             de: "Guam" },
   "GY":              { it: "Guyana",                                               en: "Guyana",                                                           de: "Guyana" },
   "HK":              { it: "Hong Kong",                                            en: "Hong Kong",                                                        de: "Hongkong" },
   "HM":              { it: "Isole Heard e McDonald",                               en: "Heard Island and McDonald Islands",                                de: "Heard und McDonaldinseln" },
   "HN":              { it: "Honduras",                                             en: "Honduras",                                                         de: "Honduras" },
   "HR":              { it: "Croazia",                                              en: "Croatia",                                                          de: "Kroatien" },
   "HT":              { it: "Haiti",                                                en: "Haiti",                                                            de: "Haiti" },
   "ID":              { it: "Indonesia",                                            en: "Indonesia",                                                        de: "Indonesien" },
   "IM":              { it: "Isola di Man",                                         en: "Isle of Man",                                                      de: "Insel Man" },
   "IO":              { it: "Territorio britannico dell'Oceano Indiano",            en: "British Indian Ocean Territory",                                   de: "Britisches Territorium im Indischen Ozean" },
   "IR":              { it: "Iran",                                                 en: "Iran, Islamic Republic of",                                        de: "Iran, Islamische Republik" },
   "IQ":              { it: "Iraq",                                                 en: "Iraq",                                                             de: "Irak" },
   "IS":              { it: "Islanda",                                              en: "Iceland",                                                          de: "Island" },
   "IL":              { it: "Israele",                                              en: "Israel",                                                           de: "Israel" },
   "JM":              { it: "Giamaica",                                             en: "Jamaica",                                                          de: "Jamaika" },
   "JE":              { it: "Jersey",                                               en: "Jersey",                                                           de: "Jersey" },
   "JO":              { it: "Giordania",                                            en: "Jordan",                                                           de: "Jordanien" },
   "KZ":              { it: "Kazakistan",                                           en: "Kazakhstan",                                                       de: "Kasachstan" },
   "KE":              { it: "Kenya",                                                en: "Kenya",                                                            de: "Kenia" },
   "KG":              { it: "Kirghizistan",                                         en: "Kyrgyzstan",                                                       de: "Kirgisistan" },
   "KH":              { it: "Cambogia",                                             en: "Cambodia",                                                         de: "Kambodscha" },
   "KI":              { it: "Kiribati",                                             en: "Kiribati",                                                         de: "Kiribati" },
   "KN":              { it: "Saint Kitts e Nevis",                                  en: "Saint Kitts and Nevis",                                            de: "St. Kitts und Nevis" },
   "KR":              { it: "Corea del Sud",                                        en: "Korea, Republic of",                                               de: "Korea, Republik" },
   "KW":              { it: "Kuwait",                                               en: "Kuwait",                                                           de: "Kuwait" },
   "LA":              { it: "Laos",                                                 en: "Lao People's Democratic Republic",                                 de: "Laos, Demokratische Volksrepublik" },
   "LB":              { it: "Libano",                                               en: "Lebanon",                                                          de: "Libanon" },
   "LR":              { it: "Liberia",                                              en: "Liberia",                                                          de: "Liberia" },
   "LY":              { it: "Libia",                                                en: "Libya",                                                            de: "Libyen" },
   "LC":              { it: "Saint Lucia",                                          en: "Saint Lucia",                                                      de: "St. Lucia" },
   "LI":              { it: "Liechtenstein",                                        en: "Liechtenstein",                                                    de: "Liechtenstein" },
   "LK":              { it: "Sri Lanka",                                            en: "Sri Lanka",                                                        de: "Sri Lanka" },
   "LS":              { it: "Lesotho",                                              en: "Lesotho",                                                          de: "Lesotho" },
   "LT":              { it: "Lituania",                                             en: "Lithuania",                                                        de: "Litauen" },
   "LV":              { it: "Lettonia",                                             en: "Latvia",                                                           de: "Lettland" },
   "MO":              { it: "Macao",                                                en: "Macao",                                                            de: "Macao" },
   "MF":              { it: "Saint-Martin (Francia)",                               en: "Saint Martin (French part)",                                       de: "Saint Martin (Französischer Teil)" },
   "MA":              { it: "Marocco",                                              en: "Morocco",                                                          de: "Marokko" },
   "MC":              { it: "Monaco",                                               en: "Monaco",                                                           de: "Monaco" },
   "MD":              { it: "Moldavia",                                             en: "Moldova",                                                          de: "Moldau" },
   "MG":              { it: "Madagascar",                                           en: "Madagascar",                                                       de: "Madagaskar" },
   "MV":              { it: "Maldive",                                              en: "Maldives",                                                         de: "Malediven" },
   "MX":              { it: "Messico",                                              en: "Mexico",                                                           de: "Mexiko" },
   "MH":              { it: "Isole Marshall",                                       en: "Marshall Islands",                                                 de: "Marshallinseln" },
   "MK":              { it: "Repubblica di Macedonia",                              en: "Macedonia, Republic of",                                           de: "Mazedonien, ehemalige jugoslawische Republik" },
   "ML":              { it: "Mali",                                                 en: "Mali",                                                             de: "Mali" },
   "MT":              { it: "Malta",                                                en: "Malta",                                                            de: "Malta" },
   "MM":              { it: "Myanmar",                                              en: "Myanmar",                                                          de: "Myanmar" },
   "ME":              { it: "Montenegro",                                           en: "Montenegro",                                                       de: "Montenegro" },
   "MN":              { it: "Mongolia",                                             en: "Mongolia",                                                         de: "Mongolei" },
   "MP":              { it: "Marianne settentrionali",                              en: "Northern Mariana Islands",                                         de: "Nördliche Marianen" },
   "MZ":              { it: "Mozambico",                                            en: "Mozambique",                                                       de: "Mosambik" },
   "MR":              { it: "Mauritania",                                           en: "Mauritania",                                                       de: "Mauretanien" },
   "MS":              { it: "Montserrat",                                           en: "Montserrat",                                                       de: "Montserrat" },
   "MQ":              { it: "Martinica",                                            en: "Martinique",                                                       de: "Martinique" },
   "MU":              { it: "Maurizio",                                             en: "Mauritius",                                                        de: "Mauritius" },
   "MW":              { it: "Malawi",                                               en: "Malawi",                                                           de: "Malawi" },
   "MY":              { it: "Malaysia",                                             en: "Malaysia",                                                         de: "Malaysia" },
   "YT":              { it: "Mayotte",                                              en: "Mayotte",                                                          de: "Mayotte" },
   "NA":              { it: "Namibia",                                              en: "Namibia",                                                          de: "Namibia" },
   "NC":              { it: "Nuova Caledonia",                                      en: "New Caledonia",                                                    de: "Neukaledonien" },
   "NE":              { it: "Niger",                                                en: "Niger",                                                            de: "Niger" },
   "NF":              { it: "Isola Norfolk",                                        en: "Norfolk Island",                                                   de: "Norfolkinsel" },
   "NG":              { it: "Nigeria",                                              en: "Nigeria",                                                          de: "Nigeria" },
   "NI":              { it: "Nicaragua",                                            en: "Nicaragua",                                                        de: "Nicaragua" },
   "NU":              { it: "Niue",                                                 en: "Niue",                                                             de: "Niue" },
   "NP":              { it: "Nepal",                                                en: "Nepal",                                                            de: "Nepal" },
   "NR":              { it: "Nauru",                                                en: "Nauru",                                                            de: "Nauru" },
   "NZ":              { it: "Nuova Zelanda",                                        en: "New Zealand",                                                      de: "Neuseeland" },
   "OM":              { it: "Oman",                                                 en: "Oman",                                                             de: "Oman" },
   "PK":              { it: "Pakistan",                                             en: "Pakistan",                                                         de: "Pakistan" },
   "PA":              { it: "Panama",                                               en: "Panama",                                                           de: "Panama" },
   "PN":              { it: "Pitcairn",                                             en: "Pitcairn",                                                         de: "Pitcairn" },
   "PE":              { it: "Perù",                                                 en: "Peru",                                                             de: "Peru" },
   "PH":              { it: "Filippine",                                            en: "Philippines",                                                      de: "Philippinen" },
   "PW":              { it: "Palau",                                                en: "Palau",                                                            de: "Palau" },
   "PG":              { it: "Papua Nuova Guinea",                                   en: "Papua New Guinea",                                                 de: "Papua-Neuguinea" },
   "PL":              { it: "Polonia",                                              en: "Poland",                                                           de: "Polen" },
   "PR":              { it: "Puerto Rico",                                          en: "Puerto Rico",                                                      de: "Puerto Rico" },
   "KP":              { it: "Corea del Nord",                                       en: "Korea, Democratic People's Republic of",                           de: "Korea, Demokratische Volksrepublik" },
   "PT":              { it: "Portogallo",                                           en: "Portugal",                                                         de: "Portugal" },
   "PY":              { it: "Paraguay",                                             en: "Paraguay",                                                         de: "Paraguay" },
   "PS":              { it: "Palestina",                                            en: "Palestine, State of",                                              de: "Palästina, Staat" },
   "PF":              { it: "Polinesia francese",                                   en: "French Polynesia",                                                 de: "Französisch-Polynesien" },
   "QA":              { it: "Qatar",                                                en: "Qatar",                                                            de: "Katar" },
   "RE":              { it: "Riunione",                                             en: "Réunion",                                                          de: "Réunion" },
   "RW":              { it: "Ruanda",                                               en: "Rwanda",                                                           de: "Ruanda" },
   "SA":              { it: "Arabia Saudita",                                       en: "Saudi Arabia",                                                     de: "Saudi-Arabien" },
   "SD":              { it: "Sudan",                                                en: "Sudan",                                                            de: "Sudan" },
   "SN":              { it: "Senegal",                                              en: "Senegal",                                                          de: "Senegal" },
   "SG":              { it: "Singapore",                                            en: "Singapore",                                                        de: "Singapur" },
   "GS":              { it: "Georgia del Sud e Sandwich australi",                  en: "South Georgia and the South Sandwich Islands",                     de: "South Georgia und die Südlichen Sandwichinseln" },
   "SH":              { it: "Saint Helena, Ascension e Tristan da Cunha",           en: "Saint Helena, Ascension and Tristan da Cunha",                     de: "St. Helena, Ascension und Tristan da Cunha" },
   "SJ":              { it: "Svalbard e Jan Mayen",                                 en: "Svalbard and Jan Mayen",                                           de: "Svalbard und Jan Mayen" },
   "SB":              { it: "Isole Salomone",                                       en: "Solomon Islands",                                                  de: "Salomoninseln" },
   "SL":              { it: "Sierra Leone",                                         en: "Sierra Leone",                                                     de: "Sierra Leone" },
   "SV":              { it: "El Salvador",                                          en: "El Salvador",                                                      de: "El Salvador" },
   "SM":              { it: "San Marino",                                           en: "San Marino",                                                       de: "San Marino" },
   "SO":              { it: "Somalia",                                              en: "Somalia",                                                          de: "Somalia" },
   "PM":              { it: "Saint-Pierre e Miquelon",                              en: "Saint Pierre and Miquelon",                                        de: "St. Pierre und Miquelon" },
   "RS":              { it: "Serbia",                                               en: "Serbia",                                                           de: "Serbien" },
   "SS":              { it: "Sudan meridionale",                                    en: "South Sudan",                                                      de: "Südsudan" },
   "ST":              { it: "São Tomé e Príncipe",                                  en: "Sao Tome and Principe",                                            de: "São Tomé und Príncipe" },
   "SR":              { it: "Suriname",                                             en: "Suriname",                                                         de: "Suriname" },
   "SZ":              { it: "Swaziland",                                            en: "Swaziland",                                                        de: "Swasiland" },
   "SX":              { it: "Sint Maarten (parte olandese)",                        en: "Sint Maarten (Dutch part)",                                        de: "Saint-Martin (Niederländischer Teil)" },
   "SC":              { it: "Seicelle",                                             en: "Seychelles",                                                       de: "Seychellen" },
   "SY":              { it: "Siria",                                                en: "Syrian Arab Republic",                                             de: "Syrien, Arabische Republik" },
   "TC":              { it: "Isole Turks e Caicos",                                 en: "Turks and Caicos Islands",                                         de: "Turks- und Caicosinseln" },
   "TD":              { it: "Ciad",                                                 en: "Chad",                                                             de: "Tschad" },
   "TG":              { it: "Togo",                                                 en: "Togo",                                                             de: "Togo" },
   "TH":              { it: "Thailandia",                                           en: "Thailand",                                                         de: "Thailand" },
   "TJ":              { it: "Tagikistan",                                           en: "Tajikistan",                                                       de: "Tadschikistan" },
   "TK":              { it: "Tokelau",                                              en: "Tokelau",                                                          de: "Tokelau" },
   "TM":              { it: "Turkmenistan",                                         en: "Turkmenistan",                                                     de: "Turkmenistan" },
   "TL":              { it: "Timor orientale",                                      en: "Timor-Leste",                                                      de: "Timor-Leste" },
   "TO":              { it: "Tonga",                                                en: "Tonga",                                                            de: "Tonga" },
   "TT":              { it: "Trinidad e Tobago",                                    en: "Trinidad and Tobago",                                              de: "Trinidad und Tobago" },
   "TN":              { it: "Tunisia",                                              en: "Tunisia",                                                          de: "Tunesien" },
   "TR":              { it: "Turchia",                                              en: "Turkey",                                                           de: "Türkei" },
   "TV":              { it: "Tuvalu",                                               en: "Tuvalu",                                                           de: "Tuvalu" },
   "TW":              { it: "Taiwan",                                               en: "Taiwan",                                                           de: "Taiwan" },
   "TZ":              { it: "Tanzania",                                             en: "Tanzania",                                                         de: "Tansania" },
   "UG":              { it: "Uganda",                                               en: "Uganda",                                                           de: "Uganda" },
   "UM":              { it: "Isole minori statunitensi",                            en: "United States Minor Outlying Islands",                             de: "United States Minor Outlying Islands" },
   "UY":              { it: "Uruguay",                                              en: "Uruguay",                                                          de: "Uruguay" },
   "UZ":              { it: "Uzbekistan",                                           en: "Uzbekistan",                                                       de: "Usbekistan" },
   "VA":              { it: "Santa Sede (Stato della Città del Vaticano)",          en: "Holy See (Vatican City State)",                                    de: "Heiliger Stuhl (Staat Vatikanstadt)" },
   "VC":              { it: "Saint Vincent e Grenadine",                            en: "Saint Vincent and the Grenadines",                                 de: "St. Vincent und die Grenadinen" },
   "VE":              { it: "Venezuela",                                            en: "Venezuela",                                                        de: "Venezuela" },
   "VG":              { it: "Isole Vergini britanniche",                            en: "Virgin Islands, British",                                          de: "Britische Jungferninseln" },
   "VI":              { it: "Isole Vergini U.S.",                                   en: "Virgin Islands, U.S.",                                             de: "Amerikanische Jungferninseln" },
   "VN":              { it: "Vietnam",                                              en: "Vietnam",                                                          de: "Vietnam" },
   "VU":              { it: "Vanuatu",                                              en: "Vanuatu",                                                          de: "Vanuatu" },
   "WF":              { it: "Wallis e Futuna",                                      en: "Wallis and Futuna",                                                de: "Wallis und Futuna" },
   "WS":              { it: "Samoa",                                                en: "Samoa",                                                            de: "Samoa" },
   "YE":              { it: "Yemen",                                                en: "Yemen",                                                            de: "Jemen" },
   "ZM":              { it: "Zambia",                                               en: "Zambia",                                                           de: "Sambia" },
   "ZW":              { it: "Zimbabwe",                                             en: "Zimbabwe",                                                         de: "Simbabwe" },
   "altre":           { it: "Altre",                                                en: "Others",                                                           de: "Andere" },
   "non_specificata": { it: "Non Specificata",                                      en: "Not Specified",                                                    de: "Nicht Angegeben" },
   "non_specificato": { it: "Non Specificata",                                      en: "Not Specified",                                                    de: "Nicht Angegeben" }
}

export { nazionalità }
