<template>
  <Rotta>
    <ContenitoreBase>
      {#if in_caricamento}
        <Caricatore/>
      {:else}
        <TabellaCodifiche
            {codifiche}
            {segmenti}
            {in_salvataggio}
            {salvataggio_riuscito}
            on:change={richiedi_codifiche}
            on:salva={salva_modifiche}/>
      {/if}
    </ContenitoreBase>
  </Rotta>
</template>

<style>
</style>

<script>
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import ContenitoreBase from "@/base/componenti/ContenitoreBase.svelte"
  import Rotta from "@/base/componenti/Rotta.svelte"
  import TabellaCodifiche from "@/una_hotels/componenti/tabelle/Codifiche.svelte"
  import { onMount } from "svelte"

  let in_caricamento = true
  let codifiche = []
  let segmenti = {}
  let in_salvataggio = false
  let salvataggio_riuscito = false

  ////
  // Richiede le codifiche.
  async function richiedi_codifiche() {
    in_caricamento = true
    salvataggio_riuscito = false

    let risposta = await retro.chiama(
      "GET",
      retro.estremi.una_hotels.codifiche)

    codifiche = risposta.contenuto.codifiche
    segmenti = risposta.contenuto.segmenti

    in_caricamento = false
  }

  ////
  // Salva le modifiche.
  async function salva_modifiche(evento) {
    in_salvataggio = true

    let parametri = {
      codifiche: Object.values(evento.detail.codifiche_da_aggiornare) }

    await retro.chiama("PATCH", retro.estremi.una_hotels.codifiche, parametri)

    in_salvataggio = false
    salvataggio_riuscito = true
  }

  onMount(richiedi_codifiche)
</script>
