import "@/base/sorgenti/svuby/biscottini.js"
import "@/base/sorgenti/svuby/colori.js"
import "@/base/sorgenti/svuby/date.js"
import "@/base/sorgenti/svuby/deposito_locale.js"
import "@/base/sorgenti/svuby/finestra.js"
import "@/base/sorgenti/svuby/liste.js"
import "@/base/sorgenti/svuby/navigatore.js"
import "@/base/sorgenti/svuby/numeri.js"
import "@/base/sorgenti/svuby/oggetti.js"
import "@/base/sorgenti/svuby/rete.js"
import "@/base/sorgenti/svuby/stringhe.js"

import { utente_corrente } from "@/base/sorgenti/svuby/utente_corrente.js"
import { localizzazione } from "@/base/sorgenti/svuby/localizzazione.js"
import { dizionario_generico } from "@/base/sorgenti/svuby/dizionario_generico"
import { nazionalità } from "@/base/sorgenti/svuby/nazionalità"
import { findFlagUrlByIso2Code as bandiera_nazione } from "country-flags-svg"

function avvia_localizzazione (dizionario) {
  return {
    ...dizionario_generico,
    ...nazionalità,
    ...dizionario
  }
}

export {
  localizzazione,
  avvia_localizzazione,
  utente_corrente,
  bandiera_nazione }

console.log("SVUBY caricato.".colore("rosso"))
localizzazione.su_cambio(localizzazione_corrente => {
  console.log(`Localizzazione corrente: ${localizzazione_corrente.colore("giallo")}`)
})
