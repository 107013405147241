<template>
  {#if filtri_pronti}
    <Modulo
        esteso={true}
        invio={evento => {pagina = 0; nuova_ricerca()}}
        bind:in_salvataggio={in_caricamento}>

      <slot/>

      {#if !ultima_pagina || pagina > 0}
        <div class="flessibile giustificati-attorno allineati-centro">
          <Bottone
              variante="primario"
              piccolo={true}
              disabilitato={pagina == 0}
              on:click={vai_a_pagina_precedente}>
            <Icona tipo="angolare-sinistra"/>
          </Bottone>
          {pagina + 1}
          <Bottone
              variante="primario"
              piccolo={true}
              disabilitato={ultima_pagina}
              on:click={vai_a_pagina_successiva}>
            <Icona tipo="angolare-destra"/>
          </Bottone>
        </div>          
      {/if}
    </Modulo>
  {:else}
    <Caricatore/>
  {/if}
</template>

<style>
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import Modulo from "@/base/componenti/Modulo.svelte"
  import { createEventDispatcher } from "svelte"
  import { onDestroy } from "svelte"
  import { tick } from "svelte"
  import { location } from "svelte-spa-router"
  import { push } from "svelte-spa-router"
  import { querystring } from "svelte-spa-router"

  export let filtri = {}
  export let pagina = 0
  export let ultima_pagina = false
  export let in_caricamento = true
  export let ricerche_pronte = 0
  export let valori_filtri = {}
  export let codifica_chiavi = (chiave) => {
    switch (chiave) {
      case "pagina":
        pagina = parseInt(valori_filtri[chiave])
        break
      case "nome":
        filtri[chiave] = [ "contiene", valori_filtri[chiave] ] 
        break
      default:
        filtri[chiave] = [ "uguale_a", valori_filtri[chiave] ] 
    }
  }

  const propaga = createEventDispatcher()

  let filtri_pronti = false

  ////
  // Nuova ricerca.
  function nuova_ricerca() {
  
    let valori_non_nulli = {}

    Object.keys(valori_filtri).forEach(chiave => {
      let valore = valori_filtri[chiave]
      if (valore == null || valore === "") return
      if (typeof valore.format == "function") valore = valore.format("%Y-%m-%d")
      valori_non_nulli[chiave] = valore
    })
    valori_non_nulli.pagina = pagina

    push(`${$location}?${serializza(valori_non_nulli)}`)
  }

  ////
  // Imposta i valori filtri.
  async function imposta_filtri() {
    in_caricamento = true
    filtri_pronti = false
    ricerche_pronte = 0
    await tick()

    valori_filtri = navigatore.parametri()
    filtri = {}

    Object.keys(valori_filtri).forEach(chiave => {
      filtri[chiave] = valori_filtri[chiave] 
      if (valori_filtri[chiave] == null || valori_filtri[chiave] === "") delete filtri[chiave]
      if (filtri[chiave] == null) return
      
      codifica_chiavi(chiave)
    })

    await tick()

    filtri_pronti = true

    propaga("ready")
  }

  ////
  // Va alla pagina precedente della ricerca
  function vai_a_pagina_precedente() {
    if (pagina == 0) return
    pagina -= 1

    nuova_ricerca()
  }

  ////
  // Va alla pagina successiva della ricerca
  function vai_a_pagina_successiva() {
    if (ultima_pagina) return
    pagina += 1

    nuova_ricerca()
  }

  const scollega = querystring.subscribe(imposta_filtri)
  onDestroy(scollega)
</script>
