/*
  -- LISTE --
  Varie funzioni di grande utilità che estendono il prototipo
  della classe Array.
*/

// Formatta un numero secondo unità e valuta.
Number.prototype.format = function(opzioni = {}) {
  let numero = this
  if (numero == 0 && opzioni.show_zero == null) { return "-" }
  if (numero == null || isNaN(numero))            { return "-" }

  let suffisso_contrazione = ""
  if (opzioni.shrink == true) {
    if (Math.abs(numero) >= 1000000)    { numero = numero / 1000000;  suffisso_contrazione = "M" }
    else if (Math.abs(numero) >= 10000) { numero = numero / 1000;     suffisso_contrazione = "K" }
  }

  let opzioni_decimali = {
    maximumFractionDigits: (opzioni.digits || 0),
    minimumFractionDigits: (opzioni.digits || 0)
  }
  let numero_formattato = Intl.NumberFormat("it-IT", opzioni_decimali).format(numero)

  if (opzioni.unit != null && opzioni.unit != "") {
    return `${numero_formattato}${suffisso_contrazione} ${Number.format_unit(opzioni.unit, opzioni.currency)}`
  } else {
    return `${numero_formattato}${suffisso_contrazione}`
  }
}

// Formatta un numero con decimali.
Number.prototype.format_digits = function(decimali = 0) {
  return Intl.NumberFormat("it-IT", {
      maximumFractionDigits: decimali,
      minimumFractionDigits: decimali })
    .format(this)
}

// Formatta l'unità.
Number.format_unit = function(unità, currency) {
  switch (unità) {
    case "valuta":
      return currency || "€"
    case "percentuale":
      return "%"
    case "punti_percentuali":
      return "pp"
    case "pura":
      return ""
    default:
      return ""
  }
}

// Converte in stringa.
Object.defineProperty(Number.prototype, "to_s", {
  get: function() {
    return this.toString()
  }
})

// Converte in data.
Object.defineProperty(Number.prototype, "to_date", {
  get: function() {
    return new Date(this)
  }
})

// Arrotonda il numero al numero di cifre decimali passate.
Number.prototype.round = function(decimali) {
  return parseFloat(this.toFixed(decimali))
}

// Chiama la funzione passata N volte.
Object.defineProperty(Number.prototype, "times", {
  value: function(funzione) {
    let array = new Array(this).fill()
    return array.map((e, i) => funzione(i))
  },
  enumerable: false,
  writable: false
})
