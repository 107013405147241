// Svuby
import "@/base/sorgenti/svuby"

// App
import App from "@/una_hotels/sorgenti/App.svelte"

const app = new App({
	target: document.body
})

export default app
