<template>
  <div class="contenitore-base"
      class:margini-negativi={margini_negativi}
      class:senza-margini-padding={senza_margini_padding}>
    <slot/>

    {#if mostra_politiche}
      <p class="copyright">
        &copy; {Date.today.year} HBenchmark s.r.l. - P.IVA03998320240
        <a href="{collegamenti_politiche.riserbo[$l]}" target="_blank">
          Privacy Policy
        </a>
        -
        <a href="{collegamenti_politiche.biscottini[$l]}" target="_blank">
          Cookie Policy
        </a>
      </p>
    {/if}
  </div>
</template>

<style>
  :global(div.contenitore-base) {
    display:        flex;
    flex-grow:      1;
    flex-direction: column;
    padding-top:    calc(var(--padding-base-main) * 2.66);
    overflow:       auto;
  }

  :global(div.contenitore-base.margini-negativi) {
    margin: 0 var(--padding-base-main-negativo);
  }

  :global(div.contenitore-base .margini-positivi) {
    margin-left:  var(--padding-base-main);
    margin-right: var(--padding-base-main);
  }

  :global(div.contenitore-base .padding-positivi) {
    padding-left:   var(--padding-base-main);
    padding-right:  var(--padding-base-main);
  }
  :global(div.contenitore-base.senza-margini-padding) {
    padding: 0;
    margin: 0;
  }

  .copyright {
    font-size:  10px;
    padding:    20px var(--padding-base-main);
    margin:     0;
    margin-top: auto;
  }
</style>

<script>
  import { localizzazione as l } from "@/base/sorgenti/svuby"

  export let mostra_politiche = true
  export let margini_negativi = false;
  export let senza_margini_padding = false;

  const collegamenti_politiche = {
    riserbo: {
      it: `https://www.iubenda.com/privacy-policy/81333460`,
      en: `https://www.iubenda.com/privacy-policy/40732172`,
      de: `https://www.iubenda.com/privacy-policy/74782468`
    },
    biscottini: {
      it: `https://www.iubenda.com/privacy-policy/81333460/cookie-policy`,
      en: `https://www.iubenda.com/privacy-policy/40732172/cookie-policy`,
      de: `https://www.iubenda.com/privacy-policy/74782468/cookie-policy`
    }
  }
</script>
