<template>
  <Rotta>
    <Titolo titolo="Strutture"/>

    <div class="contenuto-strutture">
      <NavigatoreFiltri
          bind:in_caricamento={in_caricamento}
          bind:filtri={filtri}
          bind:valori_filtri={valori_filtri}
          bind:pagina={pagina}
          {ultima_pagina}
          on:ready={richiedi_strutture}>
        <div class="filtri">
          <div class="flessibile estesi marginati allineati-fine">
            <Inserimento
                etichetta="Nome"
                segnaposto="Filtra per nome struttura"
                cancellabile={true}
                disabilitato={in_caricamento}
                bind:valore={valori_filtri.nome}/>
            <Selezione
                disabilitata={in_caricamento}
                etichetta="Stato"
                cancellabile={true}
                opzioni={stati}
                bind:valore={valori_filtri.stato}/>
          </div>
        </div>

        {#if in_caricamento}
          <Caricatore/>
        {:else}
          <div class="flessibile giustificati-in-mezzo allineati-centro">
            <div class="flessibile marginati allineati-centro">
              <span>
                {t.frase_conteggio_1[$l]} <b>{strutture.length}</b> {t.frase_conteggio_2[$l]} <b>{totale}</b>
              </span>
              <Bottone
                  variante="secondario-invertito"
                  disabilitato={avvia_esportazione}
                  on:click={evento => avvia_esportazione = true}>
                {#if avvia_esportazione}
                  <Caricatore/>
                {:else}
                  {t.esporta[$l]}
                {/if}
              </Bottone>
            </div>

            <div class="flessibile marginati allineati-centro">
              <Bottone tipo="invio" variante="primario">
                {t.filtra[$l]}
              </Bottone>
            </div>
          </div>

          <TabellaStrutture
              {strutture}
              indirizzo_esportazione_remota={indirizzo}
              {metodo}
              parametri={parametri_esportazione}
              tipo_dato={"strutture"}
              bind:avvia_esportazione={avvia_esportazione}/>
        {/if}
      </NavigatoreFiltri>
    </div>
  </Rotta>
</template>

<style>
  :global(div.contenuto-strutture){
    padding:  15px;
    overflow: auto;
    height:   calc(100% - 57px);
  }
  :global(div.contenuto-strutture > form){
    height:   100%;
    overflow: auto;
  }
  :global(div.contenuto-strutture > form div.filtri label.etichetta-giorni-da-ultima-trasmissione){
    width: fit-content;
    margin: 0 auto;
  }
  :global(div.contenuto-strutture > form div.bottone-chiusura-filtri){
    position:   absolute;
    left:       50%;
    transform:  translateX(-50%);
  }
  :global(div.contenuto-strutture > form div.bottone-chiusura-filtri){
    position:   absolute;
    left:       50%;
    transform:  translateX(-50%);
  }
  :global(div.contenuto-strutture > form div.bottone-chiusura-filtri i.icona){
    transform: rotate(90deg);
  }
  :global(div.contenuto-strutture > form div.bottone-chiusura-filtri.contrario i.icona){
    transform: rotate(-90deg);
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import NavigatoreFiltri from "@/base/componenti/NavigatoreFiltri.svelte"
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Selezione from "@/base/componenti/Selezione.svelte"
  import Titolo from "@/base/componenti/Titolo.svelte"
  import TabellaStrutture from "@/una_hotels/componenti/tabelle/Strutture.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { onMount } from "svelte"

  const t = avvia_localizzazione(dizionario_strutture)
  const stati = [
    { valore: "da_integrare",         testo: "Da Integrare" },
    { valore: "non_sta_trasmettendo", testo: "Non Sta Trasmettendo" },
    { valore: "codifiche_mancanti",   testo: "Codifiche Mancanti" },
    { valore: "tutto_a_posto",        testo: "Tutto a Posto" } ]

  let avvia_esportazione = false
  let filtri = {}
  let in_caricamento = true
  let indirizzo = retro.estremi.una_hotels.strutture
  let metodo = "POST"
  let pagina = 0
  let strutture = []
  let totale = 0
  let ultima_pagina = false
  let valori_filtri = {}

  $: parametri_esportazione = { seleziona_tutte: true, pagina, filtri }

  ////
  // Richiede le strutture.
  async function richiedi_strutture() {
    in_caricamento = true

    let risposta = await retro.chiama(metodo,
      indirizzo,
      { pagina, filtri })

    strutture = risposta.contenuto.strutture
    totale = risposta.contenuto.conteggio
    ultima_pagina = risposta.contenuto.ultima_pagina

    in_caricamento = false
  }

  // onMount(richiedi_strutture)
</script>

<script context="module">
  const dizionario_strutture = {
    filtra: {
      it: `Filtra`,
      en: ``,
      de: ``
    },
    frase_conteggio_1: {
      it: `Visualizzate`,
      en: ``,
      de: ``
    },
    frase_conteggio_2: {
      it: `su`,
      en: ``,
      de: ``
    },
  }
</script>