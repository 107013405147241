/*
  -- BISCOTTINI --
  Interfaccia semplificata per l'accesso ai cookies.
*/
window.biscottini = {}

// Prende un biscottino per nome, e ne ritorna il valore.
// È possibile passare come secondo argomento un valore predefinito,
// che verrà ritornato nel caso in cui il biscottino non esistesse.
window.biscottini.prendi = function(nome, valore_predefinito = null) {
  let name = nome + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')

  for (let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) == 0)
      return c.substring(name.length, c.length);
  }

  return valore_predefinito
}

// Deposita un biscottino con un nome e un valore. È possible
// passare un terzo argomento che indica il numero di giorni di validità
// del biscottino, al termine dei quali esso sparirà.
window.biscottini.deposita = function(nome, valore, giorni_validità = 1) {
  let d = new Date()
  d.setTime(d.getTime() + (giorni_validità * 24 * 60 * 60 * 1000))

  let scadenza  = "expires="+ d.toUTCString()
  if (giorni_validità == 0) scadenza  = "expires=session"
  let dominio   = `${window.dominio.split(":")[0]}`

  document.cookie = `${nome}=${valore}; domain=${dominio}; ${scadenza}; path=/`
}

// Deposita un biscottino con un nome e un valore. È possible
// passare un terzo argomento che indica il numero di giorni di validità
// del biscottino, al termine dei quali esso sparirà.
window.biscottini.deposita_con_dominio = function(nome, valore, dominio, giorni_validità = 1) {
  let d = new Date()
  d.setTime(d.getTime() + (giorni_validità * 24 * 60 * 60 * 1000))

  let scadenza  = "expires="+ d.toUTCString()
  if (giorni_validità == 0) scadenza  = "expires=session"
  document.cookie = `${nome}=${valore}; domain=${dominio}; ${scadenza}; path=/`
}

// Rimuove un biscottino per nome (e dal dominio corrente).
window.biscottini.rimuovi = function(nome) {
  let domini    = [
    `${window.dominio}`,
    `.${window.dominio.split(":")[0]}` ]
  let scadenza  = "Thu, 01 Jan 1970 00:00:00 UTC"

  domini.forEach(dominio => {
    document.cookie = `${nome}=; domain=${dominio}; expires=${scadenza}; path=/`
  })
}

// Rimuove un biscottino per nome e dominio personalizzato.
window.biscottini.rimuovi_da_dominio = function(nome, dominio) {
  let scadenza  = "Thu, 01 Jan 1970 00:00:00 UTC"

  document.cookie = `${nome}=; domain=${dominio}; expires=${scadenza}; path=/`
}

// Elimina tutti i biscottini.
window.biscottini.pulisci = function() {
  let cookies = document.cookie.split(";")

  for (let i = 0; i < cookies.length; i++)
    window.biscottini.rimuovi(cookies[i].split("=")[0])
}
