import { writable } from "svelte/store"
import { location } from "svelte-spa-router"

const rotte_senza_autenticazione = [
  "/accesso", "/registrazione", "recupero" ]

let utente_in_deposito = deposito_locale.prendi("utente_corrente")
const utente_corrente = writable(utente_in_deposito)

utente_corrente.cambia_in = function(nuovo_utente) {
  deposito_locale.deposita("utente_corrente", nuovo_utente)
  return utente_corrente.update(utente => nuovo_utente)
}
utente_corrente.su_cambio = utente_corrente.subscribe
utente_corrente.slogga = function(recupera_rotta = false) {
  utente_corrente.cambia_in(null)
  biscottini.rimuovi("gettone")
  biscottini.rimuovi_da_dominio("gettone", ".hbenchmark.it")
  deposito_locale.pulisci()
  let scollega = location.subscribe(rotta => {
    if (!rotte_senza_autenticazione.include(rotta)) {
      if (recupera_rotta)
        navigatore.verso(`/accesso?redirect_url=${window.location.href}`)
      else
        navigatore.verso("/accesso")
    }
  })
  scollega()
}

export { utente_corrente }
