/*
  -- COLORI --
  Aggiunge la funzione `colore` al prototipo della classe String.
  Questa funzione può essere chiamata passando come argomento
  una stringa che identifica il colore.
  Verrà restituita la stringa originale con la rappresentazione ANSI
  del colore passato.
*/
String.prototype.colore = function(colore) {
  switch (colore) {
    case "rosso":
      return `\x1b[31m${this}\x1b[0m`
    case "verde":
      return `\x1b[32m${this}\x1b[0m`
    case "giallo":
      return `\x1b[33m${this}\x1b[0m`
    case "blu":
      return `\x1b[34m${this}\x1b[0m`
    case "magenta":
      return `\x1b[35m${this}\x1b[0m`
    case "azzurro":
      return `\x1b[36m${this}\x1b[0m`
    default:
      return this
  }
}
