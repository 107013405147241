/*
  -- NAVIGATORE --
  Interfaccia semplificata per spostarsi fra le pagine.
*/
import { push, pop, replace, location } from "svelte-spa-router"
location.su_cambio = location.subscribe

window.navigatore = {
  rotta_corrente: location,
  verso: function(rotta) {
    return push(rotta)
  },
  indietro: function() {
    return pop()
  },
  cambia_rotta: function(rotta) {
    return replace(rotta)
  },
  parametri: function() {
    let query

    if (window.location.href.includes("#")) {
      query = window.location.href.split("?")[1]
    } else {
      query = window.location.search.substring(1)
    }

    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[decodeURIComponent(key)] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {}
          )
      : {}
  }
}
