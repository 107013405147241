<template>
  <div class="collegamento-barra-laterale"
      class:disabilitato={disabilitato}
      class:rotta-corrente={è_rotta_corrente}>
    {#if nome_rotta == null}
      <a tabindex="-1" href="/" on:click|preventDefault>
        <slot/>
        {#if demo}
          <Distintivo></Distintivo>
        {/if}
      </a>
    {:else}
      <a tabindex="-1" href="/" use:link={nome_rotta}>
        <slot/>
        {#if demo}
          <Distintivo></Distintivo>
        {/if}
      </a>
    {/if}
  </div>
</template>

<style>
  :global(div.collegamento-barra-laterale) {
    border-left:    2px solid transparent;
    margin-bottom:  var(--padding-base);
  }
  :global(div.collegamento-barra-laterale.disabilitato) {
    pointer-events: none !important;
  }
  :global(div.collegamento-barra-laterale.rotta-corrente) {
    border-left:    2px solid var(--colore-primario);
  }
  
  :global(div.collegamento-barra-laterale > a) {
    display:          flex;
    padding:          0 var(--padding-base);
    line-height:      40px;
    text-decoration:  none;
    color:            var(--colore-testo-leggero);
    font-weight:      800;
    font-size:        16px;
    transition:       all .3s;
    justify-content:  space-between;
    align-items:      center;
  }
  :global(div.collegamento-barra-laterale.disabilitato > a) {
    color:          var(--colore-disabilitato) !important;
  }
  :global(div.collegamento-barra-laterale > a:focus) {
    outline:          none;
  }
  :global(div.collegamento-barra-laterale > a:hover) {
    background-color: var(--colore-sorvolo);
  }
  :global(div.collegamento-barra-laterale > a:hover::before) {
    content: none;
  }
  :global(div.collegamento-barra-laterale.rotta-corrente > a) {
    color:            var(--colore-primario);
  }
</style>

<script>
  import { link } from "svelte-spa-router"
  import Distintivo from "@/base/componenti/Distintivo.svelte"

  export let nome_rotta = null
  export let determinatore_rotta_corrente = uguaglianza_nome
  export let disabilitato = false
  export let demo = false

  let è_rotta_corrente

  ////
  // Determina se è la rotta corrente in base all'uguaglianza sul nome.
  function uguaglianza_nome(nuova_rotta) {
    return nome_rotta == nuova_rotta
  }

  // Al cambio rotta, determina se è rotta corrente.
  navigatore.rotta_corrente.su_cambio(nuova_rotta => {
    è_rotta_corrente = determinatore_rotta_corrente(nuova_rotta)
  })
</script>
