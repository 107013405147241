/*
  -- DEPOSITO LOCALE --
  Interfaccia semplificata per l'accesso al localStorage.
*/
window.deposito_locale = {}

// Prende una chiave per nome, e ne ritorna il valore.
// È possibile passare come secondo argomento un valore predefinito,
// che verrà ritornato nel caso in cui la chiave non esistesse.
deposito_locale.prendi = function(chiave, valore_se_nullo = null) {
  let elemento = localStorage.getItem(chiave)

  if (elemento == null)
    return valore_se_nullo

  try {
    let elemento_processato = JSON.parse(elemento)
    return elemento_processato
  } catch {
    return elemento
  }
}

// Deposita una chiave e relativo valore nel deposito locale.
deposito_locale.deposita = function(chiave, valore) {
  if (typeof valore === "string")
    return localStorage.setItem(chiave, valore)
  else
    return localStorage.setItem(chiave, JSON.stringify(valore))
}

// Rimuove una chiave dal deposito locale.
deposito_locale.rimuovi = function(chiave) {
  return localStorage.removeItem(chiave)
}

// Rimuove tutte le chiavi dal deposito locale.
deposito_locale.pulisci = function() {
  return localStorage.clear()
}
