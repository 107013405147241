<template>
  <Tabella
      {colonne}
      {valore_cella}
      righe={strutture}
      dizionario={t}
      prima_colonna_fissa={true}
      intestazione_fissa={true}
      {indirizzo_esportazione_remota}
      {metodo}
      {parametri}
      {tipo_dato}
      bind:avvia_esportazione={avvia_esportazione}
      let:riga={riga}
      let:colonna={colonna}
      let:valore={valore}>
    {#if colonna == "nome"}
      <a 
          target="_blank"
          href="https://portal.hbenchmark.it/#/gestione_strutture/struttura?id_sessione={riga._id}">
        {valore}
      </a>
    {:else if colonna == "stato"}
      {#if valore == "tutto_a_posto"}
        <div class="flessibile allineati-centro">
          <Fanalino stato="completato"/> &nbsp; Tutto a Posto
        </div>
      {:else if valore == "da_integrare"}
        <div class="flessibile allineati-centro">
          <Fanalino stato="da_integrare"/> &nbsp; Da Integrare
        </div>
      {:else if valore == "non_sta_trasmettendo"}
        <div class="flessibile allineati-centro">
          <Fanalino stato="da_controllare"/> &nbsp; Non Sta Trasmettendo
        </div>
      {:else if valore == "codifiche_mancanti"}
        <div class="flessibile allineati-centro">
          <Fanalino stato="da_controllare"/> &nbsp; Codifiche Mancanti
        </div>
      {/if}
    {:else}
      {valore_cella(riga, colonna, valore)}
    {/if}
  </Tabella>
</template>

<style>
</style>

<script>
  import Tabella from "@/base/componenti/Tabella.svelte"
  import Fanalino from "@/base/componenti/Fanalino.svelte"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { createEventDispatcher } from "svelte"

  export let strutture = []
  export let avvia_esportazione = false
  export let colonne = [ "nome", "stato", "id_affiliazione", "id_numerico",
    "data_iscrizione", "ultima_trasmissione_il" ]
  export let indirizzo_esportazione_remota    = null
  export let metodo                           = "GET"
  export let parametri                        = {}
  export let tipo_dato                        = null

  const t = avvia_localizzazione(dizionario_strutture)
  const propaga = createEventDispatcher()

  ////
  // Determina il valore di una cella.
  function valore_cella(riga, colonna, valore, indice_riga, indice_colonna) {
    switch (colonna) {
      case "ultima_trasmissione_il":
        if (valore == null) return "-"
        return valore.to_date.format("%d %B %Y %H:%M")
      case "data_iscrizione":
        if (valore == null) return "-"
        return valore.to_date.format("%d %B %Y")
      default:
        if (valore == null) return "-"
        return valore
    }
  }
</script>

<script context="module">
  export const dizionario_strutture = {
    colonne: {
      nome: {
        it: `Nome`,
        en: ``,
        de: ``
      },
      stato: {
        it: `Stato`,
        en: ``,
        de: ``
      },
      id_affiliazione: {
        it: `ID Best Western`,
        en: ``,
        de: ``
      },
      id_numerico: {
        it: `ID HBenchmark`,
        en: ``,
        de: ``
      },
      data_iscrizione: {
        it: `Data Iscrizione`,
        en: ``,
        de: ``
      },
      ultima_trasmissione_il: {
        it: `Ultima Trasmissione`,
        en: ``,
        de: ``
      },
    }
  }
</script>
