import { writable } from "svelte/store"

const localizzazione = writable(biscottini.prendi("localizzazione", "it"))

localizzazione.su_cambio = localizzazione.subscribe
localizzazione.cambia_in = function(nuova_localizzazione) {
  biscottini.deposita("localizzazione", nuova_localizzazione)
  return localizzazione.update(localizzazione => nuova_localizzazione)
}

export { localizzazione }
