<template>
  <span class="fanalino" {stato}/>
</template>

<style>
  span.fanalino {
    position: relative;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
  }
  
  span.fanalino:before {
    content: ""; 
    position: absolute;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  /* Verde. */
  span.fanalino[stato="si"]:before,
  span.fanalino[stato="ok"]:before,
  span.fanalino[stato="attivo"]:before,
  span.fanalino[stato="completato"]:before,
  span.fanalino[stato="letta"]:before,
  span.fanalino[stato="ingerita"]:before {
    background-color: #34bc6e;
  }

  /* Rosso. */
  span.fanalino[stato="no"]:before,
  span.fanalino[stato="errore"]:before,
  span.fanalino[stato="in_errore"]:before,
  span.fanalino[stato="disattivo"]:before,
  span.fanalino[stato="da_controllare"]:before,
  span.fanalino[stato="da_completare"]:before,
  span.fanalino[stato="scaduto"]:before,
  span.fanalino[stato="annullato"]:before,
  span.fanalino[stato="pagamento_non_riuscito"]:before {
    background-color: #e62325;
  }

  /* Giallo. */
  span.fanalino[stato="in_prova"]:before,
  span.fanalino[stato="in_corso"]:before,
  span.fanalino[stato="in_coda"]:before,
  span.fanalino[stato="da_integrare"]:before,
  span.fanalino[stato="in_attesa_pagamento"]:before {
    background-color: #ffb000;
  }

  /* Nero. */
  span.fanalino[stato="forzato"]:before,
  span.fanalino[stato="bloccata"]:before {
    background-color: #20222b;
  }

  /* Grigio. */
  span.fanalino[stato="ignorata"]:before {
    background-color: #dee2e6;
  }

  /* Azzurro. */
  span.fanalino[stato="in_ingestione"]:before {
    background-color: #17a2b8;
  }

  /* Viola. */
  span.fanalino[stato="rimandato"]:before {
    background-color: #c027c0;
  }

  /* Blu. */
  span.fanalino[stato="da_recuperare"]:before {
    background-color: #3c6df0;
  }
</style>

<script>
  export let stato = "ignorato"
</script>
