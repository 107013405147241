/*
  -- DATE --
  Varie funzioni di grande utilità che estendono il prototipo
  della classe Date.
*/

// Converte una stringa in una data. Il secondo argomento è la
// granularità con cui è formattata la data.
Date.convert = function(stringa, granularità = "giornaliera") {
  let stringa_da_convertire, parti, anno, mese, settimana, giorno

  switch (granularità) {
    case "giornaliera":
      stringa_da_convertire = stringa.split(" ").slice(0, 2).join("T")
      if (stringa.include("UTC")) { stringa_da_convertire += "+0000" }
      return new Date(stringa_da_convertire)
    case "settimanale":
      anno = parseInt(stringa.split("-").first, 10)
      settimana = parseInt(stringa.split("-").last, 10)
      return new Date(anno, 0, 1).alla_settimana(settimana)
    case "mensile":
      anno = parseInt(stringa.split("-").first, 10)
      mese = parseInt(stringa.split("-").last, 10)
      return new Date(anno, mese - 1, 1)
    case "annuale":
      anno = parseInt(stringa.split("-").first, 10)
      return new Date(anno, 0, 1)
    case "calendario":
      parti   = stringa.split("/")
      anno    = parseInt(parti[2], 10)
      mese    = parseInt(parti[1], 10)
      giorno  = parseInt(parti[0], 10)
      return new Date(anno, mese - 1, giorno)
    default:
      return new Date()
  }
}

// Formatta una data con la granularità specifica.
Date.prototype.granulate = function(granularità = "giornaliera") {
  let localizzazione = biscottini.prendi("localizzazione", "it")
  let totale = { it: "Totale", en: "Total", de: "Total" }

  switch (granularità) {
    case "giornaliera":
      return this.format("%A %e %B %Y")
    case "settimanale":
      let fine_settimana = this.end_of_week
      if (this.month == fine_settimana.month)
        return `${this.format("%e")} - ${fine_settimana.format("%e %B")}`
      else
        return `${this.format("%e %B")} - ${fine_settimana.format("%e %B")}`
    case "mensile":
      return this.format("%B")
    case "annuale":
      // return this.year.in_stringa
      return this.format("%Y")
    case "totale":
      return totale[localizzazione]
    default:
      return this.format()
  }
}

// Nome della preposizione `alle`, per la formattazione
// delle date.
Object.defineProperty(Date, "at_name", {
  get: function() {
    let locale = biscottini.prendi("localizzazione", "it")
    switch (locale) {
      case "it":
        return "alle"
      case "en":
        return "at"
      case "de":
        return "um"
    }
  },
  enumerable: false
})

// Nomi dei giorni della settimana.
Object.defineProperty(Date, "week_day_names", {
  get: function() {
    let locale = biscottini.prendi("localizzazione", "it")
    switch (locale) {
      case "it":
        return [ "Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato" ]
      case "en":
        return [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
      case "de":
        return [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ]
    }
  },
  enumerable: false
})

// Nomi dei mesi.
Object.defineProperty(Date, "month_names", {
  get: function() {
    let locale = biscottini.prendi("localizzazione", "it")
    switch (locale) {
      case "it":
        return [ "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre" ]
      case "en":
        return [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
      case "de":
        return [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ]
    }
  },
  enumerable: false
})

// Formatta una data in stringa.
Date.prototype.format = function(sFormat = "date") {
  let date = this,
    locale = biscottini.prendi("localizzazione", "it"),
    localeString = `${locale}-${locale.toUpperCase()}`,
    aDays = Date.week_day_names,
    aMonths = Date.month_names,
    aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    nDay = date.getDay(),
    nDate = date.getDate(),
    nMonth = date.getMonth(),
    nYear = date.getFullYear(),
    nHour = date.getHours(),
    datePart = this.toLocaleDateString(localeString, { day: "numeric", month: "long", year: "numeric" }),
    timePart = this.toLocaleDateString(localeString, { hour: "2-digit", minute: "2-digit" }).split(" ").last,
    isLeapYear = function() {
      return (nYear%4===0 && nYear%100!==0) || nYear%400===0
    },
    getThursday = function() {
      let target = new Date(date)
      target.setDate(nDate - ((nDay+6)%7) + 3)
      return target
    },
    zeroPad = function(nNum, nPad) {
      return ((Math.pow(10, nPad) + nNum) + "").slice(1)
    }

  if (sFormat == "date") return datePart
  if (sFormat == "time") return `${datePart} ${Date.at_name} ${timePart}`

  return sFormat.replace(/%[a-z]/gi, function(sMatch) {
    return (({
      "%a": aDays[nDay].slice(0,3),
      "%A": aDays[nDay],
      "%b": aMonths[nMonth].slice(0,3),
      "%B": aMonths[nMonth],
      "%c": date.toUTCString(),
      "%C": Math.floor(nYear/100),
      "%d": zeroPad(nDate, 2),
      "%e": nDate,
      "%F": date.toISOString().slice(0,10),
      "%G": getThursday().getFullYear(),
      "%g": (getThursday().getFullYear() + "").slice(2),
      "%H": zeroPad(nHour, 2),
      "%I": zeroPad((nHour+11)%12 + 1, 2),
      "%j": zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
      "%k": nHour,
      "%l": (nHour+11)%12 + 1,
      "%m": zeroPad(nMonth + 1, 2),
      "%n": nMonth + 1,
      "%M": zeroPad(date.getMinutes(), 2),
      "%p": (nHour<12) ? "AM" : "PM",
      "%P": (nHour<12) ? "am" : "pm",
      "%s": Math.round(date.getTime()/1000),
      "%S": zeroPad(date.getSeconds(), 2),
      "%u": nDay || 7,
      "%V": (function() {
              let target = getThursday(),
                n1stThu = target.valueOf()
              target.setMonth(0, 1)
              let nJan1 = target.getDay()
              if (nJan1!==4) target.setMonth(0, 1 + ((4-nJan1)+7)%7)
              return zeroPad(1 + Math.ceil((n1stThu-target)/604800000), 2)
            })(),
      "%w": nDay,
      "%x": date.toLocaleDateString(),
      "%X": date.toLocaleTimeString(),
      "%y": (nYear + "").slice(2),
      "%Y": nYear,
      "%z": date.toTimeString().replace(/.+GMT([+-]\d+).+/, "$1"),
      "%Z": date.toTimeString().replace(/.+\((.+?)\)$/, "$1"),
    }[sMatch] || "") + "") || sMatch
  })
}

// Ritorna se stessa.
Object.defineProperty(Date.prototype, "to_date", {
  get: function() {
    return this
  }
})

// Ritorna la data in UTC.
Object.defineProperty(Date.prototype, "utc", {
  get: function() {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate(), 0, 0, 0, 0)
  }
})

// Determina se una data è compresa fra altre due date passate.
Object.defineProperty(Date.prototype, "between", {
  value: function(data_inizio, data_termine) {
    let tempo_corrente  = this.getTime()
    let tempo_inizio    = data_inizio.getTime()
    let tempo_termine   = data_termine.getTime()
    return (tempo_corrente >= tempo_inizio && tempo_corrente <= tempo_termine)
  },
  enumerable: false,
  writable: false
})

// Determina se una data è fuori dalle altre due date passate.
Object.defineProperty(Date.prototype, "outside", {
  value: function(data_inizio, data_termine) {
    return !this.between(data_inizio, data_termine)
  },
  enumerable: false,
  writable: false
})

// Ritorna la data odierna.
Object.defineProperty(Date, "today", {
  get: function() {
    return new Date()
  },
  enumerable: false
})

// Ritorna la data di ieri.
Object.defineProperty(Date, "yesterday", {
  get: function() {
    return Date.today.sub(1, "days")
  },
  enumerable: false
})

// Ritorna la data di domani.
Object.defineProperty(Date, "tomorrow", {
  get: function() {
    return Date.today.add(1, "days")
  },
  enumerable: false
})

// Ritorna i secondi della data.
Object.defineProperty(Date.prototype, "seconds", {
  get: function() {
    return this.getSeconds()
  },
  enumerable: false
})

// Ritorna i minuti della data.
Object.defineProperty(Date.prototype, "minutes", {
  get: function() {
    return this.getMinutes()
  },
  enumerable: false
})

// Ritorna le ore della data.
Object.defineProperty(Date.prototype, "hours", {
  get: function() {
    return this.getHours()
  },
  enumerable: false
})

// Ritorna il giorno del mese (1-31) della data.
Object.defineProperty(Date.prototype, "day", {
  get: function() {
    return this.getDate()
  },
  enumerable: false
})

// Ritorna il mese (1-12) della data.
Object.defineProperty(Date.prototype, "month", {
  get: function() {
    return (this.getMonth() + 1)
  },
  enumerable: false
})

// Ritorna l'anno della data.
Object.defineProperty(Date.prototype, "year", {
  get: function() {
    return this.getFullYear()
  },
  enumerable: false
})

// Ritorna il numero del giorno della settimana (1-7),
// dove 1 è lunedì e 7 domenica.
Object.defineProperty(Date.prototype, "weekday", {
  get: function() {
    return ((this.getDay() + 6) % 7) + 1
  },
  enumerable: false
})

// Determina se la data è nel fine settimana (sabato o domenica).
Object.defineProperty(Date.prototype, "in_weekend", {
  get: function() {
    return (this.weekday == 6 || this.weekday == 7)
  },
  enumerable: false
})

// Ritorna la data che corrisponde all'inizio della settimana
// in cui cadeva la data originale.
Object.defineProperty(Date.prototype, "beginning_of_week", {
  get: function() {
    let nuova_data = new Date(this)
    nuova_data.setDate(this.getDate() - (this.weekday - 1))
    return nuova_data
  },
  enumerable: false
})

// Ritorna la data che corrisponde alla fine della settimana
// in cui cadeva la data originale.
Object.defineProperty(Date.prototype, "end_of_week", {
  get: function() {
    let nuova_data = new Date(this)
    nuova_data.setDate(this.getDate() + (7 - this.weekday))
    // nuova_data.setDate(this.getDate() + (7 - this.weekday - 1))
    return nuova_data
  },
  enumerable: false
})
// Ritorna la data che inizia alla settimana del numero passato,
// riferita all'anno in cui cadeva la data originale.
Object.defineProperty(Date.prototype, "at_week", {
  value: function(numero_settimana) {
    let inizio_anno = this.inizio_anno
    if (inizio_anno.weekday <= 4)
      return new Date(inizio_anno.inizio_settimana.getTime() +
        ((numero_settimana - 1) * 7 * 24 * 60 * 60 * 1000))
    else
      return new Date(inizio_anno.inizio_settimana.getTime() +
        (numero_settimana * 7 * 24 * 60 * 60 * 1000))
  },
  enumerable: false
})

// Ritorna la data che corrisponde all'inizio del mese
// in cui cadeva la data originale.
Object.defineProperty(Date.prototype, "beginning_of_month", {
  get: function() {
    let nuova_data = new Date(this)
    nuova_data.setDate(1)
    return nuova_data
  }
})

// Ritorna la data che corrisponde alla fine del mese
// in cui cadeva la data originale.
Object.defineProperty(Date.prototype, "end_of_month", {
  get: function() {
    let nuova_data = new Date(this.getFullYear(), this.getMonth() + 1, 0)
    return nuova_data
  },
  enumerable: false
})

// Ritorna la data che corrisponde all'inizio dell'anno
// in cui cadeva la data originale.
Object.defineProperty(Date.prototype, "beginning_of_year", {
  get: function() {
    let nuova_data = new Date(this)
    nuova_data.setDate(1)
    nuova_data.setMonth(0)
    return nuova_data
  }
})

// Ritorna la data che corrisponde alla fine dell'anno
// in cui cadeva la data originale.
Object.defineProperty(Date.prototype, "end_of_year", {
  get: function() {
    let nuova_data = new Date(this)
    nuova_data.setMonth(11)
    nuova_data.setDate(31)
    return nuova_data
  },
  enumerable: false
})

// Restituisce la stessa data originale ma riferita all'anno
// successivo.
Object.defineProperty(Date.prototype, "next_year", {
  get: function() {
    let nuova_data = new Date(this)
    nuova_data.setYear(nuova_data.anno + 1)
    return nuova_data
  },
  enumerable: false
})

// Converte la data in un numero che rappresenta il marcatore
// temporale intero, ovvero il numero di millisecondi trascorsi
// dal 1 gennaio 1970.
Object.defineProperty(Date.prototype, "to_i", {
  get: function() {
    return this.getTime()
  },
  enumerable: false
})

// Avanza una data del numero specificato di unità temporali.
Date.prototype.add = function(numero, unità) {
  switch (unità) {
    case "day":
    case "days":
      return new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate() + numero,
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds())
    case "week":
    case "weeks":
      return new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate() + (numero * 7),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds())
    case "month":
    case "months":
      return new Date(
        this.getFullYear(),
        this.getMonth() + numero,
        this.getDate(),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds())
    case "year":
    case "years":
      return new Date(
        this.getFullYear() + numero,
        this.getMonth(),
        this.getDate(),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds())
    default:
      throw `unità "${unità}" non riconosciuta`
  }
}
// Retrocede una data del numero specificato di unità temporali.
Date.prototype.sub = function(numero, unità) {
  return this.add(-numero, unità)
}
// Ritorna la differenza in giorni fra la data originale e
// un'altra data.
Date.prototype.difference = function(altra_data) {
  let diff = altra_data - this
  return Math.round(diff / (1000 * 60 * 60 * 24))
}

// Ritorna la differenza, misurata in unità temporali passate,
// fra la data originale e un'altra data.
Date.prototype.difference_in = function(unità, altra_data) {
  let diff_giorni = this.difference(altra_data)
  switch (unità) {
    case "days":
      return diff_giorni
    case "weeks":
      return Math.round(diff_giorni / 7)
    case "months":
      let mesi
      mesi = (altra_data.getFullYear() - this.getFullYear()) * 12
      mesi = mesi - this.getMonth() + altra_data.getMonth()
      return mesi
    case "years":
      return altra_data.getFullYear() - this.getFullYear()
    default:
      throw `unità "${unità}" non riconosciuta`
  }
}
