/*
  -- OGGETTI --
  Varie funzioni di grande utilità che estendono il prototipo
  della classe Object.
*/

// Cicla chiavi e valori dell'oggetto.
Object.defineProperty(Object.prototype, "each", {
  value: function(funzione) {
    return Object.entries(this).forEach((coppia, indice) => funzione(...coppia))
  },
  enumerable: false,
  writable: true
})

// Mappa chiavi e valori dell'oggetto.
Object.defineProperty(Object.prototype, "map", {
  value: function(funzione) {
    return Object.entries(this).map((coppia, indice) => funzione(...coppia))
  },
  enumerable: false,
  writable: true
})

// Prende una chiave e ne restituisce il valore.
// È possibile specificare un valore predefinito qualora
// la chiave non venisse trovata.
Object.defineProperty(Object.prototype, "fetch", {
  value: function(chiave, valore_predefinito) {
    return this[chiave] || valore_predefinito
  },
  enumerable: false,
  writable: true
})

// Rimuove una chiave dall'oggetto.
Object.defineProperty(Object.prototype, "delete", {
  value: function(chiave) {
    return delete this[chiave]
  },
  enumerable: false,
  writable: true
})

// Esclude determinate chiavi dall'oggetto.
Object.defineProperty(Object.prototype, "except", {
  value: function(...chiavi) {
    let oggetto_filtrato = {}
    Object.keys(this).each(chiave => {
      if (chiavi.include(chiave)) return
      oggetto_filtrato[chiave] = this[chiave]
    })
    return oggetto_filtrato
  },
  enumerable: false,
  writable: true
})
