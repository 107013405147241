<template>
  <form 
      method={metodo}
      action={indirizzo}
      class:esteso={esteso}
      class:ristretto={ristretto}
      on:submit|preventDefault={invia}
      on:input={pulisci_errori}
      bind:this={elemento_modulo}>
    <slot/>
  </form>
</template>

<style>
  :global(form) {
    width:          61.8%;
    display:        flex;
    flex-direction: column;
    margin:         auto;
  }
  :global(form.esteso) {
    width:          100%;
  }
  :global(form.ristretto) {
    width:          50%;
  }
  :global(form > div.azioni) {
    margin-top:       14px;
    display:          flex;
    justify-content:  flex-start;
    align-items:      center;
  }
</style>

<script>
  import { createEventDispatcher } from "svelte"

  export let indirizzo      = null
  export let metodo         = "POST"
  export let parametri      = {}
  export let errori         = {}
  export let validatore     = () => {}
  export let invio          = null
  export let gestore        = null
  export let esteso         = false
  export let ristretto      = false
  export let in_salvataggio = false

  const propaga = createEventDispatcher()

  let elemento_modulo

  ////
  // Pulisce tutti gli errori del modulo.
  function pulisci_errori() {
    errori.each(chiave => errori[chiave] = null)
  }

  ////
  // Invia il modulo. Prima esegue la validazione.
  async function invia() {
    pulisci_errori()
    validatore()

    // Ci sono errori?
    if (Object.values(errori).some(errore => errore != null)) return

    if (invio != null) return invio()
    if (indirizzo == null) return gestore()

    in_salvataggio = true

    let risposta = await retro.chiama(metodo, indirizzo, parametri)

    if (gestore != null) return gestore(risposta)

    propaga("submit", { risposta })
    in_salvataggio = false
  }
</script>
