<template>
  {#if aperta}
    <div
        class="modale-rivestimento"
        transition:fade={{ duration: 300 }}>
      <div
          class="modale-contenitore"
          class:espansa={espansa}
          class:mostra-eccedenze={mostra_eccedenze}
          class:schermo-intero={schermo_intero}>
        {#if titolo != null}
          <div class="modale-intestazione">
            <h2>{titolo}</h2>
            {#if richiudibile}
              <Bottone
                  variante="secondario-invertito"
                  on:click={inizializza_chiusura}>
                <Icona tipo="chiudi"/>
              </Bottone>
            {/if}
          </div>
        {/if}
        <div
            class="modale-corpo"
            class:scorribile={scorribile}>
          <slot/>
        </div>
      </div>
    </div>
  {/if}
</template>

<style>        
  :global(div.modale-rivestimento) {
    display:          flex;
    justify-content:  center;
    position:         fixed;
    top:              0;
    left:             0;
    right:            0;
    bottom:           0;
    background:       rgba(0, 0, 0, 0.4);
    z-index:          999;
    overflow:         hidden;
  }

  /* Contenitore. */
  :global(div.modale-contenitore) {
    display:        flex;
    flex-direction: column;
    width:          38.2%;
    min-width:      400px;
    max-height:     61.8%;
    position:       absolute;
    top:            19.1%;
    background:     var(--colore-sfondo);
    box-shadow:     0 5px 10px 0 var(--colore-ombreggiatura);
  }
  :global(div.modale-contenitore.mostra-eccedenze) {
    max-height:     none;
  }
  :global(div.modale-contenitore.espansa) {
    width:      61.8%;
  }
  :global(div.modale-contenitore.schermo-intero) {
    width:      100%;
    height:     100%;
    max-height: 100%;
    top:        0;
  }

  /* Intestazione. */
  :global(div.modale-contenitore div.modale-intestazione) {
    display:        flex;
    align-items:    center;
    padding:        7px 14px;
    border-bottom:  1px solid var(--colore-bordo);
  }
  :global(div.modale-contenitore div.modale-intestazione h2) {
    flex-grow:  1;
    margin:     0;
  }

  /* Corpo. */
  :global(div.modale-contenitore div.modale-corpo) {
    flex-grow:  1;
    overflow:   hidden;
  }
  :global(div.modale-contenitore.mostra-eccedenze div.modale-corpo) {
    overflow:   initial;
  }
  :global(div.modale-contenitore div.modale-corpo.scorribile) {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  :global(div.modale-contenitore div.modale-contenuto) {
    padding:  14px;
    height:   100%;
  }
  :global(div.modale-contenitore div.modale-azioni) {
    display:          flex;
    justify-content:  flex-end;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Icona from "@/base/componenti/Icona.svelte"
  import { fade } from "svelte/transition"
  import { createEventDispatcher } from "svelte"

  export let aperta = false
  export let espansa = false
  export let scorribile = true
  export let richiudibile = true
  export let mostra_eccedenze = false
  export let titolo = null
  export let schermo_intero = false
  export let messaggio_conferma_chiusura = null

  let propaga = createEventDispatcher()

  //#
  // Chiude la modale.
  function chiudi() {
    propaga("chiusa")
    aperta = false
  }

  function inizializza_chiusura() {
    if (messaggio_conferma_chiusura != null) {
      let conferma = confirm(messaggio_conferma_chiusura)

      if (conferma) return chiudi()
      return
    }
    return chiudi()
  }
</script>
