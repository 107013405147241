<template>
  <span class="distintivo">
    {testo}
  </span>
</template>

<style>
  :global(span.distintivo){
    text-transform:     uppercase;
    padding:            4px 6px;
    background-color:   var(--colore-primario-chiaro);
    color:              var(--colore-titolo);
    font-size:          12px;
    line-height:        1;
  }
</style>

<script>
  export let testo = "demo"
</script>