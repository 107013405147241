/*
  -- LISTE --
  Varie funzioni di grande utilità che estendono il prototipo
  della classe Array.
*/

// Questo non si poteva non rinominare.. ma dimmi te..
// chi ha pensato i nomi delle funzioni di questo linguaggio
// di programmazione???
Array.prototype.each = Array.prototype.forEach

// Ritorna i primi N elementi della lista.
Object.defineProperty(Array.prototype, "first", {
  get: function() {
    return this[0]
  },
  enumerable: false,
})

// Ritorna gli ultimi N elementi della lista.
Object.defineProperty(Array.prototype, "last", {
  get: function() {
    return this[this.length - 1]
  },
  enumerable: false,
})

// Ritorna il valore minimo della lista.
Object.defineProperty(Array.prototype, "min", {
  get: function() {
    return Math.min(...this)
  },
  enumerable: false
})

// Ritorna il valore massimo della lista.
Object.defineProperty(Array.prototype, "max", {
  get: function() {
    return Math.max(...this)
  },
  enumerable: false
})

// Determina se la lista include l'elemento passato.
Object.defineProperty(Array.prototype, "include", {
  value: function(elemento) {
    return this.includes(elemento)
  },
  enumerable: false,
  writable: false
})

// Determina se la lista non include l'elemento passato.
Object.defineProperty(Array.prototype, "not_include", {
  value: function(elemento) {
    return !this.includes(elemento)
  },
  enumerable: false,
  writable: false
})

// Rimuove i valori nulli da una lista.
Object.defineProperty(Array.prototype, "compact", {
  get: function(elemento) {
    return this.filter(elemento => elemento != null)
  },
  enumerable: false
})

// Rimuove i valori duplicati da una lista.
Object.defineProperty(Array.prototype, "uniq", {
  get: function(elemento) {
    return [ ...new Set(this) ]
  },
  enumerable: false
})
